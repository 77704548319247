import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import "twin.macro"
import styled from "styled-components"
import {
  FlowHeader,
  ScreenHeader,
  StyledPopoverContentContainer,
} from "./components"
import {
  DeviceInformationModel,
  InstallationScreenEnum,
  InstallationStep,
  InstallationStepOptionalDataProps,
  InstallationStepStateEnum,
  InstallationStepType,
} from "../types"
import { useFormData } from "hooks/useFormData"
import {
  Button,
  ButtonGroup,
  Form,
  FormField,
  Icon,
  Textarea,
  ValidationInput,
} from "@clevertrack/shared"
import { FormFieldToggle } from "components/FormFieldToggle"
import { InstallationContext } from "../context"
import { useFileUpload } from "app/FileUpload/hooks"
import { PhotoUpload } from "./components/PhotoUpload"
import { DeviceTypeCategoryEnum } from "app/Devices/types"
import { useInstallation } from "../hooks"
import { getInstallationStepState } from "./helper"
import debounce from "lodash-es/debounce"
import isEqual from "lodash-es/isEqual"
import { Select } from "lib/Select"
import { getLicensePlateInfo } from "services/integrations/synsbasen"
import orderBy from "lodash-es/orderBy"
import { useFirestoreDevices } from "services/firestore/devices"
import uniq from "lodash-es/uniq"
import { useTranslation } from "react-i18next"
import { InstallationActions, InstallationTypes } from "../actions"

// Styled component for the form field with low certainty score
const StyledFormField = styled(FormField) <{ lowCertainty?: boolean }>`
  ${({ lowCertainty }) => lowCertainty && `
    border-color: #FFC107; /* Yellow border for low certainty */
  `}
`

// Reusable component for vehicle form fields with certainty scores
interface VehicleFormFieldProps {
  fieldKey: string;
  labelKey: string;
  placeholderKey: string;
  value: string | undefined;
  certaintyScore: number;
  onChange: (value: string) => void;
}

const VehicleFormField: React.FC<VehicleFormFieldProps> = ({
  fieldKey,
  labelKey,
  placeholderKey,
  value,
  certaintyScore,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  // State to track if the user has verified the field
  const [isVerified, setIsVerified] = useState(false);

  // Only show low certainty warning if the score is low AND the user hasn't verified it yet
  const hasLowCertainty = certaintyScore > 0 && certaintyScore < 0.90 && !isVerified;

  // Construct label with certainty score and verification message if needed
  const labelText = `${t(labelKey)} ${certaintyScore > 0
    ? `- ${t('installation_ai_precision')}: ${(certaintyScore * 100).toFixed(0)}%${hasLowCertainty ? ' - ' + t('installation_ai_verify_reading') : ''}`
    : ''}`;

  // Handle blur event to mark the field as verified
  const handleBlur = () => {
    if (hasLowCertainty) {
      setIsVerified(true);
    }
  };

  return (
    <StyledFormField
      label={<>{labelText}</>}
      lowCertainty={hasLowCertainty}
      {...props}
    >
      <ValidationInput
        id={fieldKey} // Using fieldKey as the unique ID for the input
        value={value}
        placeholder={t(placeholderKey)}
        onChange={(e) => onChange(e.target.value)}
        onBlur={handleBlur} // Clear low certainty warning when user interacts with the field
      />
      <Icon icon="ai-stars" tw="w-8 h-8 opacity-60 absolute right-4 bottom-4" />
    </StyledFormField>
  );
};


export const DeviceInformation: React.FC<InstallationStepType> = ({
  onSave,
  ...props
}) => {
  const {
    state: { account, device, installationSteps, currentScreen, vehicleGroups },
    dispatch,
  } = useContext(InstallationContext)

  const stepData = useMemo(() => {
    if (installationSteps) {
      const step = installationSteps.find(
        (step) => step.stepID === InstallationScreenEnum.DeviceInformation
      )
      if (step) {
        return step
      }
      return null
    }
    return null
  }, [installationSteps])

  const { t } = useTranslation()

  const [currentModel, updateModel, resetModel, setCurrentModel] = useFormData<
    DeviceInformationModel
  >(stepData?.data)
  const [certaintyScore, setCertaintyScore] = useState({ make: 0, model: 0, year: 0, vin: 0 })
  const [optionalDataProps, setOptionalDataProps] = useState<
    InstallationStepOptionalDataProps[]
  >(stepData?.optionalDataProps ?? [])
  const { deviceTypeCategory, setCurrentScreen } = useInstallation()
  const { saveFirebaseDevice } = useFirestoreDevices()
  const {
    onUploadDocumentHandler,
    uploadMap,
    removeItemFromUploadMapByKey,
  } = useFileUpload(`${account?.id}/${device?.imei}`, account?.id)

  const availableGroups = useMemo(() => {
    return orderBy(vehicleGroups, "name").map((x) => ({
      label: x.name,
      value: x.id,
    }))
  }, [vehicleGroups])

  const onSelectGroupHandler = (opt) => {
    updateModel("vehicleGroup", opt.length > 0 ? opt : null)
  }

  const autoSaveFunc = useRef(
    debounce((newData) => {
      onSave(InstallationScreenEnum.DeviceInformation, newData, null, {
        autoSave: true,
      })
    }, 2000)
  )

  useMemo(() => {
    if (
      currentModel &&
      currentScreen === InstallationScreenEnum.DeviceInformation &&
      stepData &&
      !isEqual(stepData?.data, currentModel)
    ) {
      const newData: InstallationStep = {
        ...stepData,
        data: currentModel,
        stepState: getInstallationStepState(currentModel, optionalDataProps),
        optionalDataProps: optionalDataProps,
      }

      autoSaveFunc.current.cancel()
      autoSaveFunc.current(newData)
    }
  }, [currentModel, stepData, autoSaveFunc, currentScreen])

  const onSubmitHandler = () => {
    if (autoSaveFunc.current) autoSaveFunc.current.cancel()
    const newData = {
      ...stepData,
      data: currentModel,
      stepState: getInstallationStepState(currentModel, optionalDataProps),
    }

    const deviceUpdate = {
      patchVehiclePayload: {
        name: currentModel.vehicleName,
        note: currentModel.vehicleDescription ?? "",
        customer_id: device?.customer_id ?? 0,
        subscription_type: device?.subscription_type ?? 0,
        subscription_addon: device?.subscription_addon ?? 0,
        aempid: device?.aempid ?? "",
      },
      deviceCalibrationPayload: {
        name: currentModel.vehicleName,
        note: currentModel.vehicleDescription ?? "",
      },
    }

    if (currentModel?.vehicleGroup) {
      deviceUpdate.vehicleToGroupPayload = {
        groups: currentModel?.vehicleGroup.map((x) => x.value),
      }
    }

    dispatch(InstallationActions(InstallationTypes.SetDevice, {
      device: {
        ...device,
        ...deviceUpdate,
        name: currentModel.vehicleName,
        note: currentModel.vehicleDescription ?? ""
      }
    }))

    onSave(
      InstallationScreenEnum.DeviceInformation,
      newData,
      Promise.all([saveFirebaseDevice(deviceUpdate, device.imei)])
    )
  }

  const onAcceptFilesHandler = async (files, filename, key) => {
    await onUploadDocumentHandler(files, filename, key)
  }

  const onVertexAIResponseHandler = async (result) => {
    const jsonString = result.replace("```json", "").replace("```", "")
    const json = JSON.parse(jsonString)

    console.log(json)

    if (json.make) {
      updateModel("vehicleBrand", json.make.value)
      setCertaintyScore((prev) => ({
        ...prev,
        make: json.make.score
      }))
    }

    if (json.model) {
      updateModel("vehicleModel", json.model.value)
      setCertaintyScore((prev) => ({
        ...prev,
        model: json.model.score
      }))
    }

    if (json.year) {
      updateModel("vehicleYear", json.year.value)
      setCertaintyScore((prev) => ({
        ...prev,
        year: json.year.score
      }))
    }

    if (json.vin) {
      updateModel("vehicleFrameNumber", json.vin.value)
      setCertaintyScore((prev) => ({
        ...prev,
        vin: json.vin.score
      }))
    }

    if (json.vehicleRegistrationNumber) {
      const reg = json.vehicleRegistrationNumber.value.replaceAll(" ", "")
      setCertaintyScore((prev) => ({
        ...prev,
        vehicleRegistrationNumber: json.vehicleRegistrationNumber.score
      }))
      const vehicleInfoReq = await getLicensePlateInfo(reg)
      if (vehicleInfoReq.status === 200 && vehicleInfoReq.data.data) {
        const { brand, model, vin, model_year } = vehicleInfoReq.data.data
        updateModel("vehicleRegistrationNumber", reg)
        updateModel("vehicleYear", model_year)
        updateModel("vehicleFrameNumber", vin)
        updateModel(
          "vehicleModel",
          [model.charAt(0).toUpperCase(), model.substr(1).toLowerCase()].join(
            ""
          )
        )
        updateModel(
          "vehicleBrand",
          [brand.charAt(0).toUpperCase(), brand.substr(1).toLowerCase()].join(
            ""
          )
        )
      }
    }
  }

  useMemo(() => {
    Object.values(uploadMap).map((value) => {
      if (value.key) {
        updateModel(value.key, value.downloadURL)
        updateModel(`${value.key}Path`, value.path)
      }
    })
  }, [uploadMap])

  useMemo(() => {
    const str = [currentModel?.vehicleBrand, currentModel?.vehicleModel]
    if (currentModel?.vehicleNumber) {
      if (currentModel?.vehicleBrand || currentModel?.vehicleModel) {
        str.unshift(`-`)
      }
      str.unshift(`${currentModel?.vehicleNumber}`)
    }
    if (str.filter(Boolean).length > 0)
      updateModel("vehicleName", str.filter(Boolean).join(" "))
  }, [
    currentModel?.vehicleBrand,
    currentModel?.vehicleModel,
    currentModel?.vehicleNumber,
  ])

  useMemo(() => {
    if (!currentModel) return;

    const hasLicensePlatePhoto = Boolean(currentModel.vehicleLicensePlatePhoto);
    const hasFrameNumberPhoto = Boolean(currentModel.vehicleTypePlatePhoto);
    const hasLicensePlate = Boolean(currentModel.vehicleRegistrationNumber);
    const hasFrameNumber = Boolean(currentModel.vehicleFrameNumber);
    const hasYear = Boolean(currentModel.vehicleYear);

    const descriptionParts: string[] = [];

    // Case 1: Picture of vehicle license plate and vehicleFrameNumber removed
    if (hasLicensePlate && !hasFrameNumberPhoto && hasYear) {
      descriptionParts.push(`Reg nr: ${currentModel.vehicleRegistrationNumber}`);
      descriptionParts.push(`Årg: ${currentModel.vehicleYear}`);
    }
    // Case 2: Picture of vehicleFrameNumber + vehicleLicensePlate removed
    else if (hasFrameNumber && !hasLicensePlatePhoto && hasYear) {
      descriptionParts.push(`Stel nr: ${currentModel.vehicleFrameNumber}`);
      descriptionParts.push(`Årg: ${currentModel.vehicleYear}`);
    }
    // Case 3: Picture of vehicle license plate + Picture of vehicleFrameNumber
    else if (hasLicensePlate && hasFrameNumber && hasLicensePlatePhoto && hasFrameNumberPhoto && hasYear) {
      descriptionParts.push(`Reg. nr: ${currentModel.vehicleRegistrationNumber}`);
      descriptionParts.push(`Stel nr: ${currentModel.vehicleFrameNumber}`);
      descriptionParts.push(`Årg: ${currentModel.vehicleYear}`);
    }
    // Default case: fall back to original behavior
    else {
      if (hasFrameNumber) {
        descriptionParts.push(`Stel nr: ${currentModel.vehicleFrameNumber}`);
      }
      if (hasYear) {
        descriptionParts.push(`Årg: ${currentModel.vehicleYear}`);
      }
    }

    if (descriptionParts.length > 0) {
      updateModel("vehicleDescription", descriptionParts.join(" "));
    }
  }, [
    currentModel?.vehicleFrameNumber,
    currentModel?.vehicleYear,
    currentModel?.vehicleRegistrationNumber,
    currentModel?.vehicleLicensePlatePhoto,
    currentModel?.vehicleTypePlatePhoto
  ])

  const [
    machineNumberToggled,
    licensePlateToggled,
    typePlateToggled,
  ] = useMemo(() => {
    const toggledFields = [true, true, true]
    if (optionalDataProps.includes("vehicleNumber")) {
      toggledFields[0] = false
    }

    if (
      optionalDataProps.includes("vehicleRegistrationNumber") &&
      optionalDataProps.includes("vehicleLicensePlatePhoto") &&
      optionalDataProps.includes("vehicleLicensePlatePhotoPath")
    ) {
      toggledFields[1] = false
    }

    if (
      optionalDataProps.includes("vehicleTypePlatePhoto") &&
      optionalDataProps.includes("vehicleTypePlatePhotoPath")
    ) {
      toggledFields[2] = false
    }

    return toggledFields
  }, [])

  return (
    <StyledPopoverContentContainer>
      <FlowHeader name={currentModel?.legacyVehicleName} />
      <ScreenHeader>{t("installation_device_information_title")}</ScreenHeader>
      <Form onSubmit={onSubmitHandler} {...props}>
        <div tw="p-4 space-y-8 pb-16 relative z-50">
          <FormFieldToggle
            toggleQuestion={`${t(
              "installation_device_information_machine_number_toggle_question"
            )} ${[
              DeviceTypeCategoryEnum.Beacon,
              DeviceTypeCategoryEnum.GPSTrackerWithBattery,
            ].includes(deviceTypeCategory)
              ? t(
                "installation_device_information_machine_number_toggle_text_beacon"
              )
              : t(
                "installation_device_information_machine_number_toggle_text_tracker"
              )
              }?`}
            untoggleText={t("installation_remove_field")}
            toggleText={`${t("installation_add")} ${[
              DeviceTypeCategoryEnum.Beacon,
              DeviceTypeCategoryEnum.GPSTrackerWithBattery,
            ].includes(deviceTypeCategory)
              ? t(
                "installation_device_information_machine_number_toggle_text_beacon"
              )
              : t(
                "installation_device_information_machine_number_toggle_text_tracker"
              )
              } +`}
            onDisable={() => {
              const { vehicleNumber, ...newModel } = currentModel
              setCurrentModel(newModel)
              setOptionalDataProps((prev) => {
                return uniq([...prev, "vehicleNumber"])
              })
            }}
            onEnable={() => {
              updateModel("vehicleNumber", stepData?.data?.vehicleNumber ?? "")
              setOptionalDataProps((prev) => {
                return prev.filter((x) => x !== "vehicleNumber")
              })
            }}
            defaultToggled={machineNumberToggled}
          >
            <FormField
              label={
                [
                  DeviceTypeCategoryEnum.Beacon,
                  DeviceTypeCategoryEnum.GPSTrackerWithBattery,
                ].includes(deviceTypeCategory)
                  ? t(
                    "installation_device_information_machine_number_toggle_text_beacon"
                  )
                  : t(
                    "installation_device_information_machine_number_toggle_text_tracker"
                  )
              }
            >
              <ValidationInput
                value={currentModel?.vehicleNumber || ""}
                placeholder={
                  [
                    DeviceTypeCategoryEnum.Beacon,
                    DeviceTypeCategoryEnum.GPSTrackerWithBattery,
                  ].includes(deviceTypeCategory)
                    ? t(
                      "installation_device_information_machine_number_placeholder_beacon"
                    )
                    : t(
                      "installation_device_information_machine_number_placeholder_tracker"
                    )
                }
                onChange={(e) => updateModel("vehicleNumber", e.target.value)}
              />
            </FormField>
          </FormFieldToggle>
          {deviceTypeCategory !== DeviceTypeCategoryEnum.Beacon && (
            <FormFieldToggle
              toggleQuestion={t(
                "installation_device_information_license_plate_toggle_question"
              )}
              untoggleText={t("installation_remove_field")}
              toggleText={`${t("installation_add")} ${t(
                "installation_device_information_license_plate_toggle_text"
              )}`}
              onDisable={() => {
                const {
                  vehicleRegistrationNumber,
                  vehicleLicensePlatePhoto,
                  vehicleLicensePlatePhotoPath,
                  ...newModel
                } = currentModel
                setCurrentModel(newModel)
                setOptionalDataProps((prev) => {
                  return uniq([
                    ...prev,
                    "vehicleRegistrationNumber",
                    "vehicleLicensePlatePhoto",
                    "vehicleLicensePlatePhotoPath",
                  ])
                })
              }}
              onEnable={() => {
                updateModel(
                  "vehicleRegistrationNumber",
                  stepData?.data?.vehicleRegistrationNumber ?? ""
                )
                updateModel(
                  "vehicleLicensePlatePhoto",
                  stepData?.data?.vehicleLicensePlatePhoto ?? ""
                )
                updateModel(
                  "vehicleLicensePlatePhotoPath",
                  stepData?.data?.vehicleLicensePlatePhotoPath ?? ""
                )
                setOptionalDataProps((prev) => {
                  return prev.filter(
                    (x) =>
                      ![
                        "vehicleRegistrationNumber",
                        "vehicleLicensePlatePhoto",
                        "vehicleLicensePlatePhotoPath",
                      ].some((key) => key === x)
                  )
                })
              }}
              defaultToggled={licensePlateToggled}
            >
              <h4>{t("installation_license_plate_image")}</h4>
              <PhotoUpload
                photoPropertyKey="vehicleLicensePlatePhoto"
                photoPropertyValue={currentModel?.vehicleLicensePlatePhoto}
                photoFilename="license-plate.jpeg"
                photoPath={currentModel?.vehicleLicensePlatePhotoPath}
                photoPathPropertyKey="vehicleLicensePlatePhotoPath"
                uploadLabel=""
                uploadDescription={t(
                  "installation_license_plate_image_upload_description"
                )}
                onUpdateModel={updateModel}
                onDeletePhoto={() =>
                  removeItemFromUploadMapByKey("vehicleLicensePlatePhoto")
                }
                onAcceptFilesHandler={onAcceptFilesHandler}
                onVertexAIResponse={onVertexAIResponseHandler}
                vertexPrompt="Return the license plate number of this image as JSON. Name the property 'vehicleRegistrationNumber'. The value must not contain any whitespace. Include a certainty score for each property. The format of the json should be: {<property>: {value: <value>, score: <certainty score>}} "
              />
              <VehicleFormField
                tw="mt-8"
                fieldKey="vehicleRegistrationNumber"
                labelKey="installation_device_information_license_plate_number"
                placeholderKey="installation_license_plate_number_placeholder"
                value={currentModel.vehicleRegistrationNumber}
                certaintyScore={certaintyScore.vehicleRegistrationNumber || 0}
                onChange={(value) => updateModel("vehicleRegistrationNumber", value.toUpperCase())}
              />
            </FormFieldToggle>
          )}
          <FormFieldToggle
            toggleQuestion={t(
              "installation_device_information_type_plate_toggle_question"
            )}
            untoggleText={t("installation_remove_field")}
            toggleText={`${t("installation_add")} ${t(
              "installation_device_information_type_plate_toggle_text"
            )}`}
            onDisable={() => {
              const {
                vehicleTypePlatePhoto,
                vehicleTypePlatePhotoPath,
                ...newModel
              } = currentModel
              setCurrentModel(newModel)
              setOptionalDataProps((prev) => {
                return uniq([
                  ...prev,
                  "vehicleTypePlatePhoto",
                  "vehicleTypePlatePhotoPath",
                ])
              })
            }}
            onEnable={() => {
              updateModel(
                "vehicleTypePlatePhoto",
                stepData?.data?.vehicleTypePlatePhoto ?? ""
              )
              updateModel(
                "vehicleTypePlatePhotoPath",
                stepData?.data?.vehicleTypePlatePhotoPath ?? ""
              )
              setOptionalDataProps((prev) => {
                return prev.filter(
                  (x) =>
                    ![
                      "vehicleTypePlatePhoto",
                      "vehicleTypePlatePhotoPath",
                    ].some((key) => key === x)
                )
              })
            }}
            defaultToggled={typePlateToggled}
          >
            <h4>{t("installation_type_plate_image")}</h4>
            <PhotoUpload
              photoPropertyKey="vehicleTypePlatePhoto"
              photoPropertyValue={currentModel?.vehicleTypePlatePhoto}
              photoFilename="typeplate-with-vin.jpeg"
              photoPath={currentModel?.vehicleTypePlatePhotoPath}
              photoPathPropertyKey="vehicleTypePlatePhotoPath"
              uploadLabel=""
              uploadDescription={t(
                "installation_type_plate_image_upload_description"
              )}
              onUpdateModel={updateModel}
              onDeletePhoto={() =>
                removeItemFromUploadMapByKey("vehicleTypePlatePhoto")
              }
              onAcceptFilesHandler={onAcceptFilesHandler}
              onVertexAIResponse={onVertexAIResponseHandler}
              vertexPrompt="Return make, model, year and vin (remove asterixes at start and end of values) in JSON from this image. The first letter of the 'make' value should be in uppercase. Include a certainty score for EACH property you identify, and take your time to analyze the image. The format of the json should be: {<property>: {value: <value>, score: <certainty score>}, ...}"
            />
          </FormFieldToggle>

          {/* Reusable component for form fields with certainty scores */}
          <VehicleFormField
            fieldKey="vehicleBrand"
            labelKey="installation_brand_question"
            placeholderKey="installation_brand_placeholder"
            value={currentModel.vehicleBrand}
            certaintyScore={certaintyScore.make}
            onChange={(value) => updateModel("vehicleBrand", value)}
          />

          <VehicleFormField
            fieldKey="vehicleModel"
            labelKey="installation_model_question"
            placeholderKey="installation_model_placeholder"
            value={currentModel.vehicleModel}
            certaintyScore={certaintyScore.model}
            onChange={(value) => updateModel("vehicleModel", value)}
          />

          <VehicleFormField
            fieldKey="vehicleYear"
            labelKey="installation_year_question"
            placeholderKey="installation_year_placeholder"
            value={currentModel.vehicleYear}
            certaintyScore={certaintyScore.year}
            onChange={(value) => updateModel("vehicleYear", value)}
          />

          <VehicleFormField
            fieldKey="vehicleFrameNumber"
            labelKey="installation_frame_number_question"
            placeholderKey="installation_frame_number_placeholder"
            value={currentModel.vehicleFrameNumber}
            certaintyScore={certaintyScore.vin}
            onChange={(value) => updateModel("vehicleFrameNumber", value.toUpperCase())}
          />
          <FormField label={t("installation_uniqueness_question")}>
            <Textarea
              defaultValue={currentModel.vehicleUniqueness}
              placeholder={t("installation_uniqueness_placeholder")}
              onChange={(e) => updateModel("vehicleUniqueness", e.target.value)}
            />
          </FormField>
          <FormField label={t("installation_group_question")}>
            <Select
              menuPlacement="top"
              placeholder={t("installation_group_placeholder")}
              options={availableGroups}
              isMulti
              onChange={onSelectGroupHandler}
              value={currentModel?.vehicleGroup}
              tw="pt-4"
            />
          </FormField>
          <FormField label={t("installation_name_question")}>
            <ValidationInput
              value={currentModel.vehicleName}
              placeholder={t("installation_name_placeholder")}
              onChange={(e) => updateModel("vehicleName", e.target.value)}
            />
          </FormField>
          <FormField label={t("installation_description_question")}>
            <ValidationInput
              value={currentModel.vehicleDescription}
              placeholder={t("installation_description_placeholder")}
              onChange={(e) =>
                updateModel("vehicleDescription", e.target.value)
              }
            />
          </FormField>
        </div>
        <ButtonGroup sticky="bottom" tw="bg-white px-4 z-100">
          <Button
            type="button"
            variant="cancel"
            onClick={() => setCurrentScreen(InstallationScreenEnum.Tasks)}
          >
            <span tw="flex items-center">
              <Icon icon="chevron-left" tw="w-4 h-4 mr-2" />
              <span tw="text-xl font-normal">{t("installation_back")}</span>
            </span>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={
              stepData &&
              getInstallationStepState(currentModel, optionalDataProps) !==
              InstallationStepStateEnum.Completed
            }
          >
            {t("installation_save")}
          </Button>
        </ButtonGroup>
      </Form>
    </StyledPopoverContentContainer>
  )
}
