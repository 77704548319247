import { useContext, useEffect, useMemo } from "react"
import { useParams } from "@gatsbyjs/reach-router"
import { AccountsContext } from "./context"
import { AccountTypes, AccountsActions } from "./actions"

export const useAccount = (id?: number) => {
  const { accountID } = useParams()
  const {
    state: { accounts, viewAccount },
    dispatch,
  } = useContext(AccountsContext)

  useEffect(() => {
    if (!viewAccount) {
      const acc = accounts.find((acc) =>
        accountID ? +acc.id === +accountID : id ? +acc.id === +id : null
      )
      if (acc) {
        dispatch(AccountsActions(AccountTypes.SetViewAccount, { viewAccount: acc }))
      }
    }
  }, [viewAccount, accounts, accountID, id])
}

export const useAccounts = () => {
  const {
    state: { accounts, viewAccount },
  } = useContext(AccountsContext)

  const availableAccounts = useMemo(() => {
    if (accounts.length > 0)
      return accounts.map((x) => ({
        label: x.name,
        value: {
          name: x.name,
          id: x.id,
        },
      }))
    return []
  }, [accounts])

  const assignedAccounts = useMemo(() => {
    if (accounts.length > 0 && viewAccount)
      return accounts.filter((acc) =>
        viewAccount?.assignedAccounts?.map((x) => x.accountID).includes(acc.id)
      )
    return []
  }, [accounts, viewAccount])

  return { availableAccounts, assignedAccounts }
}
